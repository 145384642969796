import { Footer, Header } from "#components/base";
import { Container } from "#components/common";

export default function Home() {
  return (
    <>
      <Header />
      <Container></Container>
      <Footer />
    </>
  );
}
